import React from 'react'
import { Col, Container, Row } from 'reactstrap'

export default function Hero({ header, subHeader, text }) {
    return (
        <div className="hero py-5 d-flex align-items-center">
            <Container>
                <Row>
                    <Col md="6" className="mx-auto text-center mt-5">
                        {subHeader && <h6 className="mt-5">
                            {subHeader}
                        </h6>}
                        <h1>{header}</h1>
                        <p>{text}</p>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
