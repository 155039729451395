import React from 'react'
import { Link } from 'react-router-dom'
import { Container, Row, Col } from 'reactstrap'
import MainChimpComp from './MainChimpComp'

function Footer({ toggle, ...props }) {
 
    return (
        <footer className="footer">
            <Container>
                <Row>
                    <Col md="8" className="text-white newsletter">
                        <h5>Join our newsletter</h5>
                        <p>We’ll send you a nice letter once per week. No spam.</p>
                    </Col>

                    <Col md="4">
                            <MainChimpComp />
                    </Col>
                </Row>
                <hr className="w-100 bg-light" />
                <Row>
                    <Col md="6" className="order-2 order-md-1">
                        <p className="small text-muted">Copyright &copy; {new Date().getFullYear()} Edoko. All rights reserved</p>

                    </Col>
                    <Col md="6" className="order-1 order-md-2 text-left text-md-right small  mb-2">

                        <Link className="mr-3" to="/terms">Terms and Condition</Link> <Link to="/privacy">Privacy Policy</Link>
                        <div className="my-4">
                            {[{name: "Instagram", href: "https://www.instagram.com/edokoapp/"}, {name: "Facebook", href: "https://www.facebook.com/profile.php?id=100075802391326"}].map(({name, href}, i) => (
                                <a href={href} target="_blank" rel="noreferrer" key={i}>
                                    <img alt={name} src={require(`../../assets/images/svgs/${name}.svg`).default} className="mr-3" />
                                </a>
                            ))}
                        </div>
                    </Col>
                </Row>
            </Container>

        </footer>
    )
}

export default Footer
