import React from 'react'
import { Col, Container, Row } from 'reactstrap'
import Hero from '../../components/common/Hero'

export default function Terms() {
    return (
        <div className="privacy">
            <Hero subHeader={`Current as of ${new Date().toLocaleDateString()}`} header="Terms and conditions" text="By accessing our website, you are agreeing to be bound by these terms of service, all applicable laws and regulations, and agree that you are responsible for compliance with any applicable local laws." />
            <Container>
                <Row>
                    <Col md="8" className="mx-auto">
                        <p className="text-muted">
                            {/* Mi tincidunt elit, id quisque ligula ac diam, amet. Vel etiam suspendisse morbi eleifend faucibus eget vestibulum felis. Dictum quis montes, sit sit. Tellus aliquam enim urna, etiam. Mauris posuere vulputate arcu amet, vitae nisi, tellus tincidunt. At feugiat sapien varius id.
Eget quis mi enim, leo lacinia pharetra, semper. Eget in volutpat mollis at volutpat lectus velit, sed auctor. Porttitor fames arcu quis fusce augue enim. Quis at habitant diam at. Suscipit tristique risus, at donec. In turpis vel et quam imperdiet. Ipsum molestie aliquet sodales id est ac volutpat.     */}
                            Please read these terms and conditions carefully before using Our Service.
                        </p>
                        <h4>Interpretation and Definitions  </h4>
                    </Col>
                </Row>
                {[{
                    header: "Interpretation",
                    text: `The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.`
                }, {
                    header: "Definitions",
                    text: `For the purposes of these Terms and Conditions:`,
                    list: ["Application means the software program provided by the Company downloaded by You on any electronic device, named Edoko", "Application Store means the digital distribution service operated and developed by Apple Inc. (Apple App Store) or Google Inc. (Google Play Store) in which the Application has been downloaded.",
                        `Affiliate means an entity that controls, is controlled by or is under common control with a party, where "control" means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.`,
                        "Account means a unique account created for You to access our Service or parts of our Service.",
                        `Country refers to: Nigeria`, `Company (referred to as either "the Company", "We", "Us" or "Our" in this
                                            Agreement) refers to Edoko Integrated Resources LTD, 68 Olorunlogbon
                                            street, Anthony Village, Lagos.
                                        `, `Device means any device that can access the Service such as a computer, a
                                        cellphone or a digital tablet.
                                    `, `Feedback means feedback, innovations or suggestions sent by You regarding
                        the attributes, performance or features of our Service.
                    `, `Goods refer to the items offered for sale on the Service.`, `Orders mean a request by You to purchase Goods from Us.`, `Service refers to the Application.`, `Terms and Conditions (also referred as "Terms") mean these Terms and
                    Conditions that form the entire agreement between You and the Company
                    regarding the use of the Service.
                    `, `Third-party Social Media Service means any services or content (including
                        data, information, products or services) provided by a third-party that
                        may be displayed, included or made available by the Service.
                    `, `You means the individual accessing or using the Service, or the company,
                    or other legal entity on behalf of which such individual is accessing or
                    using the Service, as applicable.
                `]
                }, {
                    header: "Acknowledgment  ",
                    text: ` Mi tincidunt elit, id quisque ligula ac diam, amet. Vel etiam suspendisse morbi eleifend faucibus eget vestibulum felis. Dictum quis montes, sit sit. Tellus aliquam enim urna, etiam. Mauris posuere vulputate arcu amet, vitae nisi, tellus tincidunt. At feugiat sapien varius id.
                    Eget quis mi enim, leo lacinia pharetra, semper. Eget in volutpat mollis at volutpat lectus velit, sed auctor. Porttitor fames arcu quis fusce augue enim. Quis at habitant diam at. Suscipit tristique risus, at donec. In turpis vel et quam imperdiet. Ipsum molestie aliquet sodales id est ac volutpat.`
                }, {
                    header: "How long do we keep your information?",
                    text: `These are the Terms and Conditions governing the use of this Service and the
                    agreement that operates between You and the Company. These Terms and
                    Conditions set out the rights and obligations of all users regarding the use
                    of the Service.
                    
                    Your access to and use of the Service is conditioned on Your acceptance of and
                    compliance with these Terms and Conditions. These Terms and Conditions apply
                    to all visitors, users and others who access or use the Service.

                    By accessing or using the Service You agree to be bound by these Terms and
                    Conditions. If You disagree with any part of these Terms and Conditions then
                    You may not access the Service.


                    You represent that you are over the age of 18. The Company does not permit
                    those under 18 to use the Service.

                    
                    Your access to and use of the Service is also conditioned on Your acceptance
                    of and compliance with the Privacy Policy of the Company. Our Privacy Policy
                    describes Our policies and procedures on the collection, use and disclosure of
                    Your personal information when You use the Application or the Website and
                    tells You about Your privacy rights and how the law protects You. Please read
                    Our Privacy Policy carefully before using Our Service
                    
                    `
                }, {
                    header: "Placing Orders for Goods  ",
                    text: `By placing an Order for Goods through the Service, You warrant that You are
                    legally capable of entering into binding contracts.
                    `
                }, {
                    header: "Your Information  ",
                    text: ` If You wish to place an Order for Goods available on the Service, You may be
                    asked to supply certain information relevant to Your Order including, without
                    limitation, Your name, Your email, Your phone number, Your credit card number,
                    the expiration date of Your credit card, Your billing address, and Your
                    shipping information.
                    
                    
                    You represent and warrant that: (i) You have the legal right to use any credit
                    or debit card(s) or other payment method(s) in connection with any Order; and
                    that (ii) the information You supply to us is true, correct and complete.

                    
                    By submitting such information, You grant us the right to provide the
                    information to payment processing third parties for purposes of facilitating
                    the completion of Your Order.
                    
                    `
                }, {
                    header: "Order Cancellation  ",
                    text: `We reserve the right to refuse or cancel Your Order at any time for certain
                    reasons including but not limited to:`,
                    list: ["Goods availability", "Errors in the description or prices for Goods", "Errors in Your Order"]
                },
                {
                    header: '',
                    text: 'We reserve the right to refuse or cancel Your Order if fraud or an unauthorized or illegal transaction is suspected.'
                },
                {
                    header: 'Your Order Cancellation Rights  ',
                    text: `Any Goods you purchase can only be returned in accordance with these Terms and
                    Conditions and Our Returns Policy.
                    
                    
                    Our Returns Policy forms a part of these Terms and Conditions. Please read our
                    Returns Policy to learn more about your right to cancel Your Order.
                    
                    Your right to cancel an Order only applies to Goods that are returned in the
                    same condition as You received them. You should also include all of the
                    products instructions, documents and wrappings. Goods that are damaged or not
                    in the same condition as You received them or which are worn simply beyond
                    opening the original packaging will not be refunded. You should therefore take
                    reasonable care of the purchased Goods while they are in Your possession.
                    
                    
                    We will reimburse You no later than 14 days from the day on which We receive
                    the returned Goods. We will use the same means of payment as You used for the
                    Order, and You will not incur any fees for such reimbursement.

                    
                    You will not have any right to cancel an Order for the supply of any of the
                    following Goods:                    
                    `,
                    list: ["The supply of Goods made to Your specifications or clearly personalized.", "The supply of Goods which according to their nature are not suitable to be returned, deteriorate rapidly or where the date of expiry is over.",
                        `The supply of Goods which are not suitable for return due to health
                    protection or hygiene reasons and were unsealed after delivery.`,
                        `The supply of Goods which are, after delivery, according to their nature,
                    inseparably mixed with other items.`,
                        `The supply of digital content which is not supplied on a tangible medium
                    if the performance has begun with Your prior express consent and You have
                    acknowledged Your loss of cancellation right.
                `
                    ]
                },
                {
                    header: "Availability, Errors and Inaccuracies",
                    text: `
                    We are constantly updating Our offerings of Goods on the Service. The Goods
                    available on Our Service may be mispriced, described inaccurately, or
                    unavailable, and We may experience delays in updating information regarding
                    our Goods on the Service and in Our advertising on other websites.

                    
                    We cannot and do not guarantee the accuracy or completeness of any
                    information, including prices, product images, specifications, availability,
                    and services. We reserve the right to change or update information and to
                    correct errors, inaccuracies, or omissions at any time without prior notice.
                    
                    `
                },
                {
                    header: "Prices Policy  ",
                    text: `The Company reserves the right to revise its prices at any time prior to
                    accepting an Order.
                    
                    The prices quoted may be revised by the Company subsequent to accepting an
                    Order in the event of any occurrence affecting delivery caused by government
                    action, variation in customs duties, increased shipping charges, higher
                    foreign exchange costs and any other matter beyond the control of the Company.
                    In that event, You will have the right to cancel Your Order.
                    
                    `
                },
                {
                    header: "Payments  ",
                    text: `All Goods purchased are subject to a one-time payment. Payment can be made
                    through various payment methods we have available, such as Visa, MasterCard,
                    Affinity Card, American Express cards or online payment methods (PayPal, for
                    example).
                    
                    Payment cards (credit cards or debit cards) are subject to validation checks
                    and authorization by Your card issuer. If we do not receive the required
                    authorization, We will not be liable for any delay or non-delivery of Your
                    Order.                    
                    `
                },
                {
                    header: "User Accounts",
                    text: `When You create an account with Us, You must provide Us information that is
                    accurate, complete, and current at all times. Failure to do so constitutes a
                    breach of the Terms, which may result in immediate termination of Your account
                    on Our Service.
                    
                    
                    You are responsible for safeguarding the password that You use to access the
                    Service and for any activities or actions under Your password, whether Your
                    password is with Our Service or a Third-Party Social Media Service.

                    You agree not to disclose Your password to any third party. You must notify Us
                    immediately upon becoming aware of any breach of security or unauthorized use
                    of Your account.

                    You may not use as a username the name of another person or entity or that is
                    not lawfully available for use, a name or trademark that is subject to any
                    rights of another person or entity other than You without appropriate
                    authorization, or a name that is otherwise offensive, vulgar or obscene.
               
                    `
                },
                {
                    header: "Links to Other Websites  ",
                    text: `
                    
                    Our Service may contain links to third-party web sites or services that are
                    not owned or controlled by the Company.
                    
                    The Company has no control over, and assumes no responsibility for, the
                    content, privacy policies, or practices of any third party web sites or
                    services. You further acknowledge and agree that the Company shall not be
                    responsible or liable, directly or indirectly, for any damage or loss caused
                    or alleged to be caused by or in connection with the use of or reliance on any
                    such content, goods or services available on or through any such web sites or
                    services.
                    
                    We strongly advise You to read the terms and conditions and privacy policies
                    of any third-party web sites or services that You visit.
                    
                    
                    `
                },
                {
                    header: 'Termination  ',
                    text: `
                    We may terminate or suspend Your Account immediately, without prior notice or
                    liability, for any reason whatsoever, including without limitation if You
                    breach these Terms and Conditions.
                    
                    Upon termination, Your right to use the Service will cease immediately. If You
                    wish to terminate Your Account, You may simply discontinue using the Service.
                    
                    `
                },
                {
                    header: 'Limitation of Liability    ',
                    text: `
                    Notwithstanding any damages that You might incur, the entire liability of the
                    Company and any of its suppliers under any provision of this Terms and Your
                    exclusive remedy for all of the foregoing shall be limited to the amount
                    actually paid by You through the Service or 100 USD if You haven't purchased
                    anything through the Service.
                    
                    To the maximum extent permitted by applicable law, in no event shall the
                    Company or its suppliers be liable for any special, incidental, indirect, or
                    consequential damages whatsoever (including, but not limited to, damages for
                    loss of profits, loss of data or other information, for business interruption,
                    for personal injury, loss of privacy arising out of or in any way related to
                    the use of or inability to use the Service, third-party software and/or third-
                    party hardware used with the Service, or otherwise in connection with any
                    provision of this Terms), even if the Company or any supplier has been advised
                    of the possibility of such damages and even if the remedy fails of its
                    essential purpose.
                    
                    Some states do not allow the exclusion of implied warranties or limitation of
                    liability for incidental or consequential damages, which means that some of
                    the above limitations may not apply. In these states, each party's liability
                    will be limited to the greatest extent permitted by law.
                                  
                    `
                }, {
                    header: '"AS IS" and "AS AVAILABLE" Disclaimer  ',
                    text: `
                    The Service is provided to You "AS IS" and "AS AVAILABLE" and with all faults
                    and defects without warranty of any kind. To the maximum extent permitted
                    under applicable law, the Company, on its own behalf and on behalf of its
                    Affiliates and its and their respective licensors and service providers,
                    expressly disclaims all warranties, whether express, implied, statutory or
                    otherwise, with respect to the Service, including all implied warranties of
                    merchantability, fitness for a particular purpose, title and non-infringement,
                    and warranties that may arise out of course of dealing, course of performance,
                    usage or trade practice. Without limitation to the foregoing, the Company
                    provides no warranty or undertaking, and makes no representation of any kind
                    that the Service will meet Your requirements, achieve any intended results, be
                    compatible or work with any other software, applications, systems or services,
                    operate without interruption, meet any performance or reliability standards or
                    be error free or that any errors or defects can or will be corrected.
                    
                    Without limiting the foregoing, neither the Company nor any of the company's
                    provider makes any representation or warranty of any kind, express or implied:
                    (i) as to the operation or availability of the Service, or the information,
                    content, and materials or products included thereon; (ii) that the Service
                    will be uninterrupted or error-free; (iii) as to the accuracy, reliability, or
                    currency of any information or content provided through the Service; or (iv)
                    that the Service, its servers, the content, or e-mails sent from or on behalf
                    of the Company are free of viruses, scripts, trojan horses, worms, malware,
                    timebombs or other harmful components.
                    
                    Some jurisdictions do not allow the exclusion of certain types of warranties
                    or limitations on applicable statutory rights of a consumer, so some or all of
                    the above exclusions and limitations may not apply to You. But in such a case
                    the exclusions and limitations set forth in this section shall be applied to
                    the greatest extent enforceable under applicable law.
                    
                    
                    `
                }, {
                    header: 'Governing Law  ',
                    text: `
                                
                    The laws of the Country, excluding its conflicts of law rules, shall govern
                    this Terms and Your use of the Service. Your use of the Application may also
                    be subject to other local, state, national, or international laws.


                    `
                },
                {
                    header: 'Disputes Resolution  ',
                    text: `If You have any concern or dispute about the Service, You agree to first try
                    to resolve the dispute informally by contacting the Company.
                    
                    `
                }, {
                    header: `For European Union (EU) Users  `,
                    text: `
                    
                    
                    If You are a European Union consumer, you will benefit from any mandatory
                    provisions of the law of the country in which you are resident in.
`
                }, {
                    header: 'United States Legal Compliance  ',
                    text: `
                    You represent and warrant that (i) You are not located in a country that is
                    subject to the United States government embargo, or that has been designated
                    by the United States government as a "terrorist supporting" country, and (ii)
                    You are not listed on any United States government list of prohibited or
                    restricted parties.
                    
                    `
                }, {
                    header: 'Severability and Waiver  ',
                    text: ''
                }, {
                    header: 'Severability  ',
                    text: `
                    
                    If any provision of these Terms is held to be unenforceable or invalid, such
                    provision will be changed and interpreted to accomplish the objectives of such
                    provision to the greatest extent possible under applicable law and the
                    remaining provisions will continue in full force and effect.
                    `
                },
                {
                    header: 'Waiver  ',
                    text: `
                    
                    Except as provided herein, the failure to exercise a right or to require
                    performance of an obligation under this Terms shall not effect a party's
                    ability to exercise such right or require such performance at any time
                    thereafter nor shall be the waiver of a breach constitute a waiver of any
                    subsequent breach
                    
                    `
                }, {
                    header: 'Translation Interpretation',
                    text: `
                    
                    These Terms and Conditions may have been translated if We have made them
                    available to You on our Service. You agree that the original English text
                    shall prevail in the case of a dispute.
                    
                    `
                }, {
                    header: 'Changes to These Terms and Conditions  ',
                    text: `
                    
                    We reserve the right, at Our sole discretion, to modify or replace these Terms
                    at any time. If a revision is material We will make reasonable efforts to
                    provide at least 30 days' notice prior to any new terms taking effect. What
                    constitutes a material change will be determined at Our sole discretion.
                    
                    By continuing to access or use Our Service after those revisions become
                    effective, You agree to be bound by the revised terms. If You do not agree to
                    the new terms, in whole or in part, please stop using the website and the
                    Service.
                    `
                }, {
                    header: 'Contact Us  ',
                    text: `
                    If you have any questions about these Terms and Conditions, You can contact
us:

                    `,
                    list: ['By email: edokoapp@gmail.com', "By visiting this page on our website: [www.edoko.app](www.edoko.app)", "By phone number: +2349066166644"]
                }

                ].map((data, i) => (
                    <Row>
                        <Col key={i} md="8" className="mx-auto">
                            <h3>{data.header}</h3>
                            <p className="text-muted">
                                {data.text}
                            </p>
                            {data.list && <ul>{data.list.map((item, i) => (
                                <li key={i}>{item}</li>
                            ))} </ul>}
                        </Col>
                    </Row>

                ))}
            </Container>
        </div>
    )
}
