import React from 'react'
import { Container, Row } from 'reactstrap'
import Footer from '../components/common/Footer'
import Header from '../components/common/Header'
import ScrollToTop from '../components/common/ScrollToTop'
import ShoppingComponent from '../pages/Landing/SubComponents/ShoppingComponent'

export default function OtherPagesLayout({ children, ...props }) {
  return (
    <ScrollToTop>
      <Header />
      {children}
      <Container>
        <Row>
          <ShoppingComponent />
        </Row>
      </Container>
      <Footer />
    </ScrollToTop>
  )
}
