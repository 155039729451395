import React, { useState } from 'react'
import { Col, Container, Row } from 'reactstrap'
import Hero from '../../../components/common/Hero'
import Form from './Form'
import Track from './Track'

const MapSubComponent = ({ activeTab, ...props }) => {
    const obj = {
        '1': <Form {...props} />,
        '2': <Track {...props} />
    }

    return obj[activeTab]
}
export default function TrackOrder() {
    const [state, setState] = useState({
        activeTab: '1',
        orderDetails: {

        }
    })

    return (
        <div className="order mb-5" style={{marginTop: state.activeTab === "1"? "5px" : "120px"}}>
            {state.activeTab === "1" && <Hero text="Follow your orders in real time, from shipping to delivery" header="Track your orders" />}
            <Container>
                <Row>
                    <Col md={state.activeTab === "1" ? "5" : "10"} className="m-auto">
                        {/* <Form /> */}
                        <MapSubComponent orderDetails={state.orderDetails} activeTab={state.activeTab} setState={setState} />
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
