import React from 'react';
import { Col, Row } from 'reactstrap';
import Hero from '../../components/common/Hero';
import Form from './Form';
import { IntercomProvider, useIntercom } from 'react-use-intercom';

const ContactContent = () => {
  const { boot } = useIntercom();
  boot();

  return (
    <div className='contact'>
      {/* <Container> */}
      <Hero
        subHeader='Contact us'
        header='We’d love to hear from you'
        text='Our friendly team is always here to chat.'
      />
      <Row className='mx-0'>
        <Col
          md='6'
          className='d-flex align-items-center justify-content-center pt-5 order-2 order-md-1'
        >
          <div>
            <div>
              {[
                {
                  header: 'Phone',
                  text: '+(23)49066166644',
                  subText: 'Our friendly team is here to help.',
                  icon: 'fa-phone',
                  href: 'tel:+2349066166644',
                },
                {
                  header: 'Email',
                  subText: 'Come say hello at our office HQ.',
                  text: 'hi@edoko.app',
                  icon: 'fa-envelope',
                  href: 'mailto:hi@edoko.app',
                },
                {
                  header: 'Office',
                  subText: 'Come say hello at our office HQ.',
                  text: ` 20 BABATUNDE KUBOYE STREET, LEKKI PHASE1, LAGOS `,
                  icon: 'fa-building-o',
                  href: '#',
                },
              ].map((data, i) => (
                <div
                  key={i}
                  className='card main-card text-left text-md-center mb-md-3 mb-0'
                >
                  <div className='card-body d-flex d-md-block'>
                    <div className='d-block d-md-none'>
                      <img
                        src={
                          require('../../assets/images/svgs/icons/ic-blaze.svg')
                            .default
                        }
                        width='50'
                        alt=''
                      />
                    </div>
                    <div>
                      <h4>{data.header}</h4>
                      <p className='mb-1 text-muted text-center'>
                        {data.subText}
                      </p>
                      <a href={data.href} className='my-3 text-dark'>
                        {data.text}
                      </a>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Col>
        <Col
          md='6'
          className='d-flex align-items-center pl-md-5 pt-5  order-1 order-md-2'
        >
          <Form />
        </Col>
      </Row>
    </div>
  );
};
export default function Contact() {
  const APP_ID = process.env.REACT_APP_INTERCOM_APP_ID;

  console.log('APP_ID', APP_ID);
  return (
    <IntercomProvider appId={APP_ID}>
      <ContactContent />
    </IntercomProvider>
  );
}
