import React, { useState } from 'react'
import Footer from '../../components/common/Footer'
import Header from '../../components/common/Header'
import HastleComponent from './SubComponents/HastleComponent'
import HowtoShop from './SubComponents/HowtoShop'
import ProcessComponent from './SubComponents/ProcessComponent'
import ShoppingComponent from './SubComponents/ShoppingComponent'
import TopComponent from './SubComponents/TopComponent'


export default function Landing() {
    const [toggler, setToggler] = useState({
        contact: false,
        order: false
    })
    const toggle = (param = {
        contact: false,
        order: false
    }) => setToggler({
        ...toggler,
        ...param
    })
    return (
        <>
            <Header toggler={toggler} toggle={toggle} />
            <div className="landing">
                <TopComponent />
                <HastleComponent />
                <HowtoShop />
                <ProcessComponent toggle={toggle}/>
                <ShoppingComponent />
            </div>
            <Footer toggle={toggle} />

        </>
    )
}
