import React from 'react'
import MailchimpSubscribe from "react-mailchimp-subscribe";
import SubscribeForm from './SubscribeForm';

export default function MainChimpComp() {

    const postUrl = `https://app.us20.list-manage.com/subscribe/post?u=${process.env.REACT_APP_MAILCHIMP_U}&id=${process.env.REACT_APP_MAILCHIMP_ID}`;
    return (
        <div>
            <MailchimpSubscribe
                url={postUrl}
                render={({ subscribe, status, message }) => (
                    <SubscribeForm
                        status={status} 
                        message={message}
                        onValidated={formData => subscribe(formData)}
                    />
                )}
            />
        </div>
    )
}
