import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { Button } from 'reactstrap'

export default function SubscribeForm({ status, message, onValidated }) {
    console.log({ status, message })
    let [state, setState] = useState({
        email: '',
        loading: false
    })
    const handleSubmit = (e) => {
        e.preventDefault();
        state.email &&
            state.email.indexOf("@") > -1 &&
            onValidated({
                EMAIL: state.email
            });
        setState((prev) => ({
            ...prev,
            loading: true
        }))
    }

    useEffect(() => {
        if (status === "success") {
            toast.success(message || "Successful")
            setState((prev) => ({
                ...prev,
                email: '',
                loading: false
            }))
        } else if (message) {
            toast.error(message)
            setState((prev)=>({
                ...prev,
                loading: false
            }))
        }
    }, [status, message])
    return (
        <form onSubmit={handleSubmit} className="d-md-flex d-block">
            <div className="d-flex mr-3 mb-3 w-100 footer-input align-items-center">
                <img alt="" src={require(`../../assets/images/svgs/Email.svg`).default} className="mr-3" />

                <input onChange={(e) => setState((prevState) => ({ ...prevState, email: e.target.value }))} value={state.email} className="w-100" type="email" required={true} placeholder="Enter your email" name="email" id="mce-email"></input>
            </div>
            <Button color="yellow" className="text-white ml-auto mb-3 d-none d-sm-block">{state.loading ? "loading..." : "Subscribe"}</Button>
            <Button color="yellow" block className="text-white ml-auto mb-3 d-block d-sm-none">{state.loading ? "loading..." : "Subscribe"}</Button>
        </form>
    )
}
