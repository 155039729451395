import React from 'react'
import { Col, Container, Row } from 'reactstrap'

export default function HowtoShop() {
    const tabs = [
        {
            header: 'Explore',
            content: 'Search and explore your favorite categories and shops from our list',
            index: '1',
            imgOrder: 'order-2 order-md-2',
            textOrder: 'order-1 order-md-1'
        },
        {
            header: 'Shop',
            content: 'Add preferred items from your favorite store to your cart.',
            index: '2',
            imgOrder: 'order-2 order-md-1',
            textOrder: 'order-1 order-md-2'
        },
        {
            header: 'Checkout',
            content: 'After Viewing Products, Proceed to checkout, select method of payment and checkout.',
            index: '3',
            imgOrder: 'order-2 order-md-2',
            textOrder: 'order-1 order-md-1'
        }
    ]

    return (
        <div id="HowtoShop" className="mt-5">
            <Container>
                <Row>
                    <Col md="6" className="mx-auto text-center">
                        <h3 className="small-header">How to Shop</h3>
                        <p className="text-muted">Shopping made Easy. Learn how to shop from the best  stores on Edoko with our step by step guide.</p>
                    </Col>
                </Row>
            </Container>
            <Container>
                {tabs.map((tab) => (
                    <Row key={tab.index}>
                        <Col md="6" className={`my-2 ${tab.imgOrder}`}>
                            <img alt="" src={require(`../../../assets/images/svgs/howToShop/${tab.index}.svg`).default} className="img-fluid" />

                        </Col>
                        <Col md="6" className={`my-2 ${tab.textOrder}`}>
                            <div className="d-flex h-100 align-items-center text-center text-md-left">
                                <div>
                                    <div className="index">
                                        {/* {tab.index} */}
                                        <img alt="" src={require(`../../../assets/images/svgs/icons/ic-shop-${tab.index}.svg`).default} width="60" />

                                    </div>
                                    <h5>{tab.header}</h5>
                                    <p className="text-muted">{tab.content}</p>
                                </div>
                            </div>
                        </Col>
                    </Row>

                ))}
            </Container>
        </div>
    )
}
