import React from "react";
import { Col, Container, Row } from "reactstrap";

export const DownloadImages = ({
  isFlex = false,
  justifyCenter = true,
  ...props
}) => {
  return (
    <div
      className={`d-${isFlex ? "flex" : "block"} d-md-flex justify-content-${
        justifyCenter ? "center" : "left"
      } w-100`}
    >
      <div className="mr-2 mb-2">
        <a href="https://apps.apple.com/ca/app/edoko/id1600923527">
          <img
            alt=""
            src={
              require("../../../assets/images/svgs/AppleStoreIcon.svg").default
            }
          />
        </a>
      </div>
      <div className="mb-2">
        <a href="https://play.google.com/store/apps/details?id=com.edako.edako">
          <img
            alt=""
            src={require("../../../assets/images/svgs/GooglePlay.svg").default}
            className="mr-2"
          />
        </a>
      </div>
    </div>
  );
};
export default function ShoppingComponent() {
  return (
    <div className="mb-0 mb-md-5">
      <Container id="shopping">
        <Row>
          <Col md="6" className="d-flex align-items-center">
            <div className="p-md-5 py-3 text-center text-md-left">
              <h1 className="small-header text-center text-md-left mt-4">
                Start Shopping now!
              </h1>
              <p>
                Join thousands of users shopping from their favorite stores
                around the world on the Edoko App without any payment barriers.
              </p>

              <div className="mt-4">
                <h5>Get the App</h5>
                <div className="d-none d-md-block">
                  <DownloadImages isFlex={true} justifyCenter={false} />
                </div>
                <div className="d-block d-md-none">
                  <DownloadImages isFlex={true} justifyCenter={true} />
                </div>
              </div>
            </div>
          </Col>
          <Col md="6">
            <img
              src={require(`../../../assets/images/svgs/Slider3.svg`).default}
              alt=""
              className="img-fluid"
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
}
