import React from 'react'
import { Link } from 'react-router-dom'
import { Col, Container, Row } from 'reactstrap'

export default function ProcessComponent({ toggle, ...props }) {
    return (
        <div id="checkoutProcess">
            <Container>
                <Row className="">
                    <Col md="4" className=" my-3 text-center text-md-left">
                        <h1 className="small-header">
                            Quick and instant checkout Process
                        </h1>
                        <h5 className="text-muted font-weight-light my-3">
                            Flexible shopping and checkout process.
                        </h5>
                    </Col>
                    <Col md="4">
                        <div className="inner-card pt-3 ">
                            <img alt="" src={require('../../../assets/images/svgs/icons/ic-checkout-wallet.svg').default} width="70" className="mb-3" />
                            <h4 className="font-weight-500">Checkout with Wallet</h4>
                            <p className="text-muted">Fund your in-app wallet and get access to exclusive offers.</p>
                        </div>
                    </Col>
                    <Col md="4">
                        <div className="inner-card pt-3 ">
                            <img alt="" src={require('../../../assets/images/svgs/icons/ic-checkout-card.svg').default} width="70" className="mb-3" />
                            <h4 className="font-weight-500">Checkout with Card</h4>
                            <p className="text-muted">Use any of your local cards to shop, and get charged directly into your account.</p>
                        </div>

                    </Col>
                </Row>
            </Container>
            <div className="track d-md-none d-block mx-4">
                <div className="text-white text-center text-md-left d-flex h-100 align-items-center">
                    <div className="py-5 px-3">
                        <h3 className="small-header text-center text-md-left">Track your orders</h3>
                        <p className="my-5 sub-text">
                            After shopping on edoko, you can track orders, we also provide notifications anytime there is an update on your order.
                        </p>
                        <Link to="/order" className="btn btn-light">Track Order</Link>
                    </div>
                </div>
            </div>
            <div className="d-md-block d-none">
                <Container className="track  mt-5">
                    <Row>
                        <Col md="6" className="d-none d-md-block">
                            <img src={require(`../../../assets/images/svgs/track-main.svg`).default} alt="" className="w-100" />

                        </Col>
                        <Col md="6">
                            <div className="text-white text-center text-md-left d-flex h-100 align-items-center">
                                <div className="p-5 p-md-4">
                                    <h3 className="small-header text-center text-md-left">Track your orders</h3>
                                    <p className="my-4 sub-text text-left">
                                        After shopping on edoko, you can track orders, we also provide notifications anytime there is an update on your order.
                                    </p>
                                    <Link to="/order" className="btn btn-light">Track Order</Link>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>

        </div>
    )
}
