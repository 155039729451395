import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { DownloadImages } from './ShoppingComponent';

const TopComponent = (props) => {
  return (
    <div id='top-half'>
      <Container>
        <Row>
          <Col md='8' className='mx-auto mt-3'>
            <div className='text-center mt-5'>
              <h1>
                Shop Without
                <br /> Borders.
              </h1>
              <p className='my-4 sub-text'>
                Enjoy unrestricted shopping across your favorite International
                online stores while you pay in Naira, No spending limits and get
                the items delivered to your doorstep.
              </p>
              <DownloadImages isFlex={false} />
            </div>
          </Col>
        </Row>
        <Row>
          <Col md='9' className='mx-auto mt-5'>
            <img
              src={require(`../../../assets/images/svgs/Slider2.svg`).default}
              alt='shopping'
              className='w-100'
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default TopComponent;
