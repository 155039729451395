import React from 'react'
import { Col, Container, Row } from 'reactstrap'

export default function HastleComponent() {
    return (
        <div >
            <Container className="pt-5" id="hastle">
                <Row>
                    <Col md="8" className="text-center mb-5 mx-auto">
                        <div className="hastle-right h-100 align-items-center d-flex">
                            <div>
                                <h1 className="small-header">
                                    Don’t go through the hastle of thinking about exorbitant shipping fees while shopping
                                </h1>
                                <p className="text-muted mt-4 sub-text">Buy from some of your favourite online stores</p>
                            </div>
                        </div>
                    </Col>
                    </Row>
                    <Row>
                    <Col md="12" className="hastle-icons">
                        <Row className="px-md-5 p-0">
                            {[...Array(8)].map((e, i) => (
                                <Col  md="auto" key={i} className="my-2 mx-auto" xs="3">
                                    <img alt="" src={require(`../../../assets/images/svgs/brands/brand${i + 1}.svg`).default} className="img-fluid" />
                                </Col>
                            ))}
                        </Row>
                        <Row>
                            
                        {[...Array(9)].map((e, i) => (
                                <Col md="auto" key={i} className="my-2 mx-auto"  xs="3">
                                    <img alt="" src={require(`../../../assets/images/svgs/brands/brand${i + 9}.svg`).default} className="img-fluid" />
                                </Col>
                            ))}
                        </Row>
                        {/* <img src={require('../../../assets/images/svgs/brands.svg').default} className="img-fluid" /> */}
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
