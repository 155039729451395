import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Col, Container, Row, Collapse } from 'reactstrap'
import Icofont from 'react-icofont';
import Hero from '../../components/common/Hero';

export default function Faq() {
    const [collapse, setCollapse] = useState(false);
    const [activeState, setActiveState] = useState("0")
    const toggle = (param) => {
        setCollapse(!collapse)
        setActiveState(param === activeState ? "" : param)
    };
    let questionsArr = [
        {
            mainHeader: "Shop at Edoko",
            mainState: "1",
            subContents: [{
                question: 'What is Edoko?',
                answer: 'Edoko manages the payment and logistics between foreign stores and African consumers. Major foreign stores in-demand are listed on the app and our users can easily shop from the stores without worrying about spending limit or logistics.',
                state: "1"
            }, {
                question: 'How do I shop on Edoko.',
                answer: "Register, pick a store you would like to shop from, explore all your favorite items and start shopping.",
                state: "2"
            }, {
                question: 'Can I talk to a customer representative while shopping?',
                answer: `Yes you can talk to a customer representative while shopping within our customer representative working hours.`,
                state: "3"
            }, {
                question: 'Are my items insured incase of damages?',
                answer: `There is an optional insurance for clothing items and compulsory insurance for Electronic items.`,
                state: "4"
            }],
        },
        {
            mainHeader: "Registration",
            mainState: "2",
            subContents: [{
                question: 'How do I Signup?',
                answer: `Just download the app from either Google Playstore or Apple Store. Click on the sign up button and follow the quick registration steps.`,
                state: "1"
            }, {
                question: 'Can I shop as a guest or Registered Customer',
                answer: `You can only shop as a registered customer because of the information needed to complete the shopping.`,
                state: "2"
            }, {
                question: 'Can I Modify my Details?',
                answer: `Yes you can modify your details at will.`,
                state: "3"
            }, {
                question: 'I have forgotten my password',
                answer: `Click on the reset password to have a link sent to your email, with that, you could update a new password`,
                state: "4"
            }, {
                question: 'Can I cancel My Account?',
                answer: `Yes you can disable your account.`,
                state: "5"
            }],
        },
        {
            mainHeader: "Payment",
            mainState: "3",
            subContents: [{
                question: 'Accepted Payment Methods.',
                answer: `Debit Cards and Bank Transfer`,
                state: "1"
            }, {
                question: 'Can I pay with my Naira Card?',
                answer: `Yes, you can pay with your cards and other payment options available in the app including bank transfer.`,
                state: "2"
            }, {
                question: 'Does the $100 Limit on your card affect my shopping?',
                answer: `No it doesn’t affect your shopping, you can shop without limit.`,
                state: "3"
            }, {
                question: 'Is there a standard exchange rate?',
                answer: `Depends on the bank rate and black market rate at the said time of shopping, we are excited to give you the best rate in the market at anytime.`,
                state: "4"
            }, {
                question: 'How secured are my card details?',
                answer: `Edoko doesn’t store your card details, your card information lies with our payment processor I.e Flutter wave and the card information is very secured with them.`,
                state: "5"
            }, {
                question: 'My credit card was declined?',
                answer: `if the card is still active, you could try again later or try a new card, you can also use bank transfer option if that would work with you.`,
                state: "6"
            }, {
                question: 'How do I delete a payment method on my account',
                answer: `There are options on the top of every payment method, you can easily swipe to remove delete it.`,
                state: "7"
            }],
        },
        {
            mainHeader: "Order",
            mainState: "4",
            subContents: [{
                question: 'Can I track my order?',
                answer: `Yes all orders can be tracked.`,
                state: "1"
            }, {
                question: 'Can I return my order if it’s a wrong order?',
                answer: `The return policy of the store involved is applied in such situation.`,
                state: "2"
            }, {
                question: 'Can I Cancel or modify my order after Payment or checkout?',
                answer: `You can always contact the customer service to see if your order could be canceled. All shipped orders cannot be canceled. If the orders are yet to be shipped, it could be canceled, modified or re-ordered`,
                state: "3"
            }],
        },
        {
            mainHeader: "Shipping",
            mainState: "5",
            subContents: [{
                question: 'How long will my delivery take?',
                answer: `5 to 7 days from the UK and 7 to 14 days from USA and other part of the world.`,
                state: "1"
            }, {
                question: 'Do I have to pay for customs and clearing fee?',
                answer: `Custom and clearing fees would be included in the shipping cost when you shop from USA and UK, if for a reason we incur unexpected charges on your item, the extra charges would be communicated with you accordingly`,
                state: "2"
            }, {
                question: 'What Shipping Options does Edoko offer?',
                answer: `We offer the vendors shipping option for stores that ship directly to Nigeria and Edoko express shipping for all stores. With Edoko express shipping, you are guaranteed of timely and door step delivery.`,
                state: "3"
            }],
        },
        {
            mainHeader: "Stores",
            mainState: "6",
            subContents: [{
                question: 'Can I shop from more than one store?',
                answer: `Yes you can shop from more than one store, but you can only checkout one store at a time, when you checkout from that store, you can proceed to checkout from other stores differently.`,
                state: "1"
            }, {
                question: 'How do I shop from a shop that is not listed?',
                answer: `Go to custom order on the app, fill in the details and most importantly upload the url links to the items you want to purchase from the stores that are not listed on the platform.`,
                state: "2"
            }, {
                question: 'Can I shop for other items that are not clothing?',
                answer: `Yes you can shop non clothing items`,
                state: "3"
            }, {
                question: 'Can I shop for someone in Diaspora?',
                answer: `Yes you can shop for someone in diaspora, all you need is to delivery address of the person you are shopping for, and the items would be delivered to the selected address.`,
                state: "4"
            }],
        }
    ]
    return (
        <div id="faq">
            <Hero subHeader="Support" header="Frequently Asked Question" text="We are here to help you get answers to the most frequently asked questions on how to shop on Edoko." />
            <Container>
                <Row>
                    <Col md="4">
                        {questionsArr.map((item, i) => (
                            <div key={i} className="mb-3">
                                <a href={`#${item.mainHeader.replace(/\s/g, '').toLowerCase()}`} className="h5 text-black">
                                    {item.mainHeader}
                                </a>
                            </div>
                        ))}

                    </Col>
                    <Col md="8">
                        {questionsArr.map((item, i) => (
                            <div id={item.mainHeader.replace(/\s/g, '').toLowerCase()}>
                                <h5 className="mb-3" key={i}>{item.mainHeader}</h5>
                                {item.subContents.map((subItem, subIndex) => (
                                    <div className="main-card card mb-3" key={subIndex}>
                                        <div className="card-body">
                                            <div className="d-flex align-items-center cursor-pointer my-3" onClick={() => toggle(`${subItem.state}${item.mainState}`)}>
                                                <h5 className="mb-0 font-weight-500">{subItem.question}</h5>

                                                {/* <i className="fal fa-abacus faq-icon"></i> */}
                                                <Icofont icon={`${activeState === `${subItem.state}${item.mainState}` ? "minus-circle" : "plus-circle"}`} className={`ml-auto text-muted`} />
                                            </div>
                                            <Collapse
                                                isOpen={activeState === `${subItem.state}${item.mainState}`}
                                            >
                                                <span className="text-muted">{subItem.answer}</span>
                                            </Collapse>
                                        </div>

                                    </div>

                                ))}
                            </div>
                        ))}
                    </Col>
                </Row>
            </Container>
            <div className="bg-grey py-5">
                <Container>
                    <Row>
                        <Col md="10" className="mx-auto">
                            <div className="card text-center rounded-5">
                                <div className="card-body">
                                    <h5 className="mt-3">Still have questions?</h5>
                                    <p className="text-muted text-center">Can’t find the answer you’re looking for? Please chat to our friendly team.</p>
                                    <Link to="/contact" className="btn btn-dark text-white my-2">Get in touch</Link>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}
