import moment from 'moment'
import React from 'react'
import { Button, Col, Row } from 'reactstrap'


const getStatusColor = (status) => {
    switch (status) {
        case "order_recieved":
        case "order_processed":
        case "out_for_delivery":
        case "delivered":
            return { color: "#27AE60", icon: "SuccessIcon" }
        case "cancled":
        case "order_cancled":
            return {color: "#E65925", icon: "CancelIcon"}
        default:
            return { color: "#ECF0F4", icon: "PendingIcon" }
    }
}
export default function Track({ orderDetails, setState,...props }) {
    // console.log({orderDetails})
    let { order, statuses } = orderDetails
    const handleGoBack = ()=>{
        setState((prevState)=>({
            ...prevState,
            orderDetails: {
                
            },
            activeTab: "1"
        }))
    }
    return (
        <Row>
            <Col md="6">
                <Button color="dark" onClick={handleGoBack} className="rounded-pill" size="sm" outline>Go Back</Button>
                <h3 className="mb-4">{order?.tracking_id}</h3>
                {/* <span className="success-status p-3">Delivered</span>
                <p className="mt-4 mb-0">Delivered on Thursday,  31 Oct, 2021, 12:00 AM</p> */}

                <span style={{background: getStatusColor(statuses[statuses.length - 1]?.status).color === "#27AE60"? "#EEFFF5" : '#E65925'}} className="px-3 py-2 text-success text-capitalize rounded-pill">{statuses ? statuses[statuses.length - 1]?.status?.replace(/_/g, " ") : null}</span>
                <p className="mt-4 mb-0 text-capitalize">{statuses ? `${statuses[statuses.length - 1]?.status?.replace(/_/g, " ")} at ` : null}{statuses ? moment(statuses[statuses.length - 1]?.created_at).format('DD MMM, YYYY HH:mm') : null}</p>

                <div className="card bg-grey mt-4 mb-5">
                    <div className='card-body d-flex align-items-center'>
                        <img alt="" width="40" className="mr-3" src={require('../../../assets/images/svgs/info.svg').default} />
                        <p className="m-0 ">Check your mail for detailed tracking information
                            and updates as well</p>
                    </div>
                </div>
                <div className="mt-5 information-div">
                    <h6>Additional Information</h6>
                    <div className="d-md-flex d-block">
                        <p className="mb-1 text-muted small">
                            User:
                        </p>
                        <p className="ml-auto mb-1">
                            {order?.user}
                        </p>
                    </div>
                    <div className="d-md-flex d-block">
                        <p className="mb-1 text-muted small">
                            Store:
                        </p>
                        <p className="ml-auto mb-1">
                            {order?.products[0]?.store?.name || "N/A"}
                        </p>
                    </div>
                    <div className="d-md-flex d-block">
                        <p className="mb-1 text-muted small">
                            Destination country:
                        </p>
                        <p className="ml-auto mb-1">
                            {order?.address?.country || "N/A"}
                        </p>
                    </div>
                    <div className="d-md-flex d-block">
                        <p className="mb-1 text-muted small">
                            City:
                        </p>
                        <p className="ml-auto mb-1">
                            {order?.address?.city || "N/A"}

                        </p>
                    </div>
                    {/* <div className="d-md-flex d-block">
                        <p className="mb-1">
                            Email:
                        </p>
                        <p className="ml-auto mb-1">
                            olubusayodavid@gmail.com
                        </p>
                    </div>
                    <div className="d-md-flex d-block">
                        <p className="mb-1">
                            Destination country:
                        </p>
                        <p className="ml-auto mb-1">
                            Nigeria
                        </p>
                    </div>
                    <div className="d-md-flex d-block">
                        <p className="mb-1">
                            City:
                        </p>
                        <p className="ml-auto mb-1">
                            Lagos
                        </p>
                    </div>
                    <div className="d-md-flex d-block">
                        <p className="mb-1">
                            Origin country:
                        </p>
                        <p className="ml-auto mb-1">
                            Singapore
                        </p>
                    </div> */}
                </div>
            </Col>
            <Col md="6">
                <div className="tracking-div mt-5 mt-md-1">
                    <div className="inner">
                        {
                            // [
                            //     {
                            //         header: "Order information entry",
                            //         date:'17 Oct, 2021',
                            //         delivered: true
                            //     },
                            //     {
                            //         header: "Order received",
                            //         date:'17 Oct, 2021',
                            //         delivered: true
                            //     },
                            //     {
                            //         header: "Order issued",
                            //         date:'17 Oct, 2021',
                            //         delivered: true
                            //     },
                            //     {
                            //         header: "item is in transit",
                            //         date:'22 Oct, 2021',
                            //         delivered: true
                            //     },
                            //     {
                            //         header: "Item departed from origin",
                            //         date:'23 Oct, 2021',
                            //         delivered: false
                            //     },
                            //     {
                            //         header: "Accepted in the city of the recipient",
                            //         date:'27 Oct, 2021',
                            //         delivered: false
                            //     },
                            //     {
                            //         header: "Item Delivered",
                            //         date:'32 Oct, 2021',
                            //         delivered: false
                            //     }
                            // ].
                            statuses?.map((track, i) => (
                                <div key={i} className="d-flex p-3 item">
                                    <div className="mr-3 img-div">
                                        {/* <span className="line" style={{ backgroundColor: track?.delivered ? "#27AE60" : "#ECF0F4" }}></span> */}
                                        <span className="line" style={{ backgroundColor: getStatusColor(track?.status).color }}></span>
                                        {/* <img alt="" src={require(`../../../assets/images/svgs/${track?.delivered ? "SuccessIcon" : "PendingIcon"}.svg`).default} /> */}
                                        <img alt="" src={require(`../../../assets/images/svgs/${getStatusColor(track?.status).icon}.svg`).default} />
                                    </div>
                                    <div className="contents-div">
                                        <p className="text-capitalize small mb-0 font-weight-bold">{track?.action?.replace(/_/g, " ") || track?.status?.replace(/_/g, " ")}</p>
                                        <small>
                                            {/* {track.created_at} */}
                                            {moment(track.created_at).format('DD MMM YYYY hh:mm:ss')}
                                        </small>
                                    </div>
                                </div>
                            ))}
                    </div>
                </div>
            </Col>
        </Row>
    )
}
